import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFile, faIdCard, faCalendar, faScrewdriverWrench, faDiagramProject} from "@fortawesome/free-solid-svg-icons";
const Services8 = () => {
  return (
    <section className="app-services section-padding bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10">
            <div className="s-head text-center mb-80">
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="item mb-30">
              <div className="item-tit mb-15">
                <FontAwesomeIcon icon={faFile} className="ml-2 mr-3" style={{ color: "#38b2ac", height: "1.5rem" }} />
                <div className="text-tit">
                  <h5> linkbioBR Drive Space</h5>
                </div>
              </div>

              <p>
              Gerar links dinâmicos baixáveis para arquivos para arquivos de diversos formatos. 
              Com recursos avançados do linkbioBR🇧🇷 Drive Space, 
              você pode definir até uma senha de segurança para acesso ao arquivo, 
              um recursos que não existe nem no Google Drive.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item mb-30">
              <div className="item-tit mb-15">
              <FontAwesomeIcon icon={faIdCard} className=" mr-3" style={{ color: "#38b2ac", height: "1.5rem" }} />
                <div className="text-tit">
                  <h5>links Vcard</h5>
                </div>
              </div>

              <p>
              Crie cartões de contato digitais dinâmicos. 
              Um link vCard é como um cartão de visita digital que organiza 
              virtualmente informações como dados de contato, nome da pessoa, 
              telefones de contato, e-mails e muitas outras que achar relevantes.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item mb-30">
              <div className="item-tit mb-15">
              <FontAwesomeIcon icon={faCalendar} className=" mr-3" style={{ color: "#38b2ac", height: "1.5rem" }} />
                <div className="text-tit">
                  <h5>Links de eventos</h5>
                </div>
              </div>

              <p>
              Crie arquivos de calendário dinâmicos e baixáveis. 
              Adicione informações para divulgar seu evento, como descrição, data, hora, local. 
              Registre um arquivo ICS para que sua audiência 
              possa gerar o evento em calendários como Google Agenda e Outlook.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item sm-mb50">
              <div className="item-tit mb-15">
              <FontAwesomeIcon icon={faScrewdriverWrench} className=" mr-3" style={{ color: "#38b2ac", height: "1.5rem" }} />
                <div className="text-tit">
                  <h5>Ferramentas Gratuitas</h5>
                </div>
              </div>

              <p>
              Enquanto você não decide criar uma conta no linkbioBR, 
              aproveite as nossas ferramentas gratuitas. 
              No momento disponibilizamos 141 ferramentas muito úteis para você poupar tempo e esforço.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="item sm-mb50">
              <div className="item-tit mb-15">
              <FontAwesomeIcon icon={faDiagramProject} className=" mr-3" style={{ color: "#38b2ac", height: "1.5rem" }} />
                <div className="text-tit">
                  <h5>Projetos</h5>
                </div>
              </div>

              <p>
              Crie projetos para organizar suas Linkpages, QR Codes, links encurtados e muito mais. 
              Organize em pastas denominando por equipes, produtos, campanhas ou outras formas.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="item sm-mb50">
              <div className="item-tit mb-15">
              <FontAwesomeIcon icon={faDiagramProject} className=" mr-3" style={{ color: "#38b2ac", height: "1.5rem" }} />
                <div className="text-tit">
                  <h5>Lorem Ipsum</h5>
                </div>
              </div>

              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tempus nunc in purus efficitur, 
              a rhoncus sapien auctor. Sed porta, mi at elementum auctor, leo dolor iaculis dui.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services8;
