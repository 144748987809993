import React from "react";

const Clients3 = () => {
  return (
    <section className="brands pt-80 pb-80 bg-dark" data-overlay-dark="0">
      <div className="container">
        <div className="row">
          {/* Coluna 1 */}
          <div className="col-lg-4 col-sm-12">
            <div className="item text-center">
              <h3 className="title-numberSection">Links e Linkpages ativos no <br/>linkbioBR</h3>
              <span className="display-4" style={{
                background: "-webkit-linear-gradient(45deg, pink, purple)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "10px"
              }}>12K+</span>
            </div>
          </div>

          {/* Coluna 2 */}
          <div className="col-lg-4 col-sm-12">
            <div className="item text-center">
              <h3 className="title-numberSection">QR Codes gerados pelo <br/>linkbioBR</h3>
              <span className="display-4" style={{
                background: "-webkit-linear-gradient(45deg, #20c997, #007bff)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "10px"
              }}>24+</span>
            </div>
          </div>

          {/* Coluna 3 */}
          <div className="col-lg-4 col-sm-12">
            <div className="item text-center">
              <h3 className="title-numberSection">Visualizações em páginas criadas com linkbioBR</h3>
              <span className="display-4" style={{
                background: "-webkit-linear-gradient(45deg,#007bff, purple)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "10px"
              }}>1,224,717+</span>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-blur"></div>
    </section>
  );
};

export default Clients3;
