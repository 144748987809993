import React from "react";
import { Link } from "gatsby";
import { handleMobileDropdown } from "common/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faUserPlus } from "@fortawesome/free-solid-svg-icons";



const NavbarMobileApp = ({ lr, nr, theme }) => {
  const handleLoginRedirect = () => {
    // Adicione aqui a lógica de redirecionamento para a página de login
    // Por exemplo:
     window.location.href = 'https://link.bio.br/login'; // redireciona para Home
  };

  return (
    <nav ref={nr} className={`navbar navbar-expand-lg change ${theme === "themeL" ? "light" : ""}`}>
      <div className="container">
        <Link to="/" className="logo">
          <img className="linkBio-logo" src="/img/Logo-LinkBio.png" alt="" />    
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          onClick={handleMobileDropdown}
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="https://link.bio.br/plan" className="nav-link">
                Planos
              </Link>
            </li>
            <li className="nav-item">
              <Link to="https://link.bio.br/tools" className="nav-link">
                Ferramentas gratuitas
              </Link>
            </li>
            <li className="nav-item">
              <button className="btn btn-outline-primary" onClick={handleLoginRedirect}>
              <FontAwesomeIcon icon={faArrowRightFromBracket} className="ml-2 mr-2" />Entrar
              </button>
            </li>
            <li className="nav-item">
              <button className="btn btn-outline-secundary" onClick={handleLoginRedirect}>
              <FontAwesomeIcon icon={faUserPlus} className="ml-2 mr-2" />Comece grátis
              </button>
            </li>
            
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarMobileApp;
