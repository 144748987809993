import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp} from "@fortawesome/free-solid-svg-icons";



const Accordion = () => {
  const myStyle = {
    marginTop: "60px",
    marginBottom: "60px"
  };

  return (
    <div className="container" style={myStyle}>
      <div className="row justify-content-center">
        <div className="col-lg-7 col-md-10">
          <div className="s-head text-center mb-80">
            <h6 className="stit mb-30">
              FAQ
            </h6>
            <h2>Respostas para suas perguntas comuns</h2>
          </div>
        </div>
      </div>

      <div className="accordion" id="accordionExample">
        {/* Item 1 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
          <button className="accordion-button" id='btn-1' type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{borderRadius: "10px 10px 0 0", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <span className='question' style={{marginRight: "auto"}}>Meus dados estão seguros com o LinkBio?</span>
            <span className="icon-circle">
              <FontAwesomeIcon  icon={faAngleUp} style={{fontSize: "0.5em", color:"white"}} />
            </span>
          </button>
          </h2>
          <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
              Sim, seus dados estão seguros com o LinkbioBR. Priorizamos a segurança e privacidade de nossos usuários. 
              Utilizamos criptografia e seguimos as melhores práticas do setor para manter suas informações seguras.
            </div>
          </div>
        </div>
        {/* Item 2 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
          <button className="accordion-button" id='btn-2' type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <span className='question' style={{marginRight: "auto"}}>Posso personalizar a aparência da minha página?</span>
            <span className="icon-circle">
              <FontAwesomeIcon  icon={faAngleUp} style={{fontSize: "0.5em", color:"white"}} />
            </span>
          </button>
          </h2>
          <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Sim, você pode personalizar a aparência da sua página do LinkbioBR. 
            No painel de administração, vá para a seção "Personalização". 
            Aqui, você pode escolher temas, cores e fontes para combinar com sua marca ou estilo.
            </div>
          </div>
        </div>
        {/* Item 3 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
          <button className="accordion-button" id='btn-3' type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <span className='question' style={{marginRight: "auto"}}>Como possom obter ajuda se encontrar problemas?</span>
            <span className="icon-circle">
              <FontAwesomeIcon  icon={faAngleUp} style={{fontSize: "0.5em", color:"white"}} />
            </span>
          </button>
          </h2>
          <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Se você encontrar algum problema ou precisar de assistência, entre em contato com nossa equipe de suporte. 
            Você pode nos alcançar através de nossa página de contato, e nossa equipe dedicada ficará feliz em ajudá-lo.
            </div>
          </div>
        </div>
        {/* Item 4 */}
        <div className="accordion-item">
          <h2 className="accordion-header">
          <button className="accordion-button" id='btn-4' type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{borderRadius: "0px 0px 10px 10px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <span className='question' style={{marginRight: "auto"}}>Existe um teste gratuito disponível?</span>
            <span className="icon-circle">
              <FontAwesomeIcon  icon={faAngleUp} style={{fontSize: "0.5em", color:"white"}} />
            </span>
          </button>
          </h2>
          <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Sim, oferecemos um período de teste gratuito para nossa plataforma. 
            Durante o teste, você pode explorar nossos recursos e decidir se o LinkbioBR é a opção certa para você. 
            Não é necessário cartão de crédito para iniciar o teste.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
