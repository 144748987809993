import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Parallax } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

SwiperCore.use([Pagination, Parallax]);

const Testimonials = () => {
  const [load, setLoad] = useState(false);
  const paginationRef = React.useRef(null);

  useEffect(() => {
    setLoad(true);
  }, [load]);

  return (
    <section className="app-testim section-padding bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-10">
            <div className="s-head text-center mb-80">
              <h6 className="stit mb-30">
                Feedback de Clientes
              </h6>
              <h2>O que falam sobre nós</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="swiper-container">
              {
                load ? (
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    speed={1000}
                    loop={true}
                    pagination={{
                      clickable: true,
                      el: paginationRef.current,
                    }}
                    className="swiper-wrapper"
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      767: {
                        slidesPerView: 2,
                        centeredSlides: false,
                      },
                      991: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    <SwiperSlide className="swiper-slide">
                      <div className="item">
                        <div className="icon mb-50">
                          <img src="/img/mobile-app/qoute.png" alt="" />
                        </div>
                        <div className="text">
                          <p>
                          A Linkbio revolucionou meu trabalho online, uma virada de jogo para criativos. Não imagino minha vida sem meus linkbios.
                          </p>
                        </div>
                        <div className="info">
                          <div className="img">
                            <img src="/img/mobile-app/clients/1.png" alt="" />
                          </div>
                          <div className="cont">
                            <h6 className="mb-10">Andréa</h6>
                            <span>Blog de Conteúdo</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="item">
                        <div className="icon mb-50">
                          <img src="/img/mobile-app/qoute.png" alt="" />
                        </div>
                        <div className="text">
                          <p>
                          O Linkbio revolucionou meu negócio online, facilitando conexões com minha audiência. É simples, versátil e vital para minha presença online.
                          </p>
                        </div>
                        <div className="info">
                          <div className="img">
                            <img src="/img/mobile-app/clients/2.png" alt="" />
                          </div>
                          <div className="cont">
                            <h6 className="mb-10">Rogério</h6>
                            <span>Empreendedor</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="item">
                        <div className="icon mb-50">
                          <img src="/img/mobile-app/qoute.png" alt="" />
                        </div>
                        <div className="text">
                          <p>
                          “ Linkbiobr é a ferramenta de links definitiva. 
                          É intuitivo, repleto de recursos e tornou a gestão 
                          da minha identidade digital muito fácil. Altamente recomendado! ”
                          </p>
                        </div>
                        <div className="info">
                          <div className="img">
                            <img src="/img/mobile-app/clients/3.png" alt="" />
                          </div>
                          <div className="cont">
                            <h6 className="mb-10">Carlos</h6>
                            <span>Gestor de tráfego</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                      <div className="item">
                        <div className="icon mb-50">
                          <img src="/img/mobile-app/qoute.png" alt="" />
                        </div>
                        <div className="text">
                          <p>
                            unde omnis iste natus error sit voluptatem accusantium
                            dolore laudantium totam rem aperiam eaqusa quae abillo
                            inventore veritatis architect beatae vitae dicta
                            ecabo.
                          </p>
                        </div>
                        <div className="info">
                          <div className="img">
                            <img src="/img/mobile-app/clients/1.png" alt="" />
                          </div>
                          <div className="cont">
                            <h6 className="mb-10">William Glenn</h6>
                            <span>Senior Manager</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                ) : ("")
              }
              <div ref={paginationRef} className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
