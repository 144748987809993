import React from "react";
import { CSSTransition } from "react-transition-group";
import "/static/css/gallery.css"; // Importe o arquivo CSS aqui

const StaticImageGallery = () => {
  return (
  <section className="team-vid section-padding bg-dark" data-overlay-dark="0">
    <div className="container" style={{ maxWidth: "1170px" }}>
      <div className="row justify-content-center">
        <div className="col-lg-7 col-md-10">
          <div className="s-head text-center mb-80">
            <h6 className="stit mb-30 asdf">Gerador de Imagens</h6>
            <h2>Essas são as 12 últimas imagens criadas por usuários usando nossa IA.</h2>
          </div>
        </div>
      </div>
      <div className="row">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
          <div key={index} className="col-md-3 mb-4">
            <CSSTransition classNames="fade-up" timeout={300}>
              <img
                src={`/img/IA-images/${index}.png`}
                alt=""
                className="img-fluid rounded"
                style={{ animation: "fade-up 0.3s ease-in-out" }} // Temporário para verificar se a animação está sendo aplicada
              />
            </CSSTransition>

          </div>
        ))}
      </div>
    </div>
   </section> 
  );
};

export default StaticImageGallery;
