import React, { useState } from "react";

const PricePackages = ({ bgGray }) => {
  const [isMonthly, setIsMonthly] = useState(true); // Estado para controlar se é mensal ou anual

  // Função para alternar entre mensal e anual
  const togglePrice = () => {
    setIsMonthly(!isMonthly); // Inverte o estado atual
  };

  return (
    <section className={`app-price section-padding ${bgGray ? "bg-gray" : ""}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="s-head text-center mb-80">
              <h6 className="stit mb-30">
                 PLANOS E PREÇOS
              </h6>
              <h2>Estamos aqui para apoiar brasileiros como nós na multiplicação de suas conexões, link a link. </h2>
            </div>
          </div>
        </div>

        {/* Botão de alternância */}
        <div className="text-center mb-4">
                  <button className={`toggle-button ${isMonthly ? "monthly selected" : "annual"}`} onClick={togglePrice}>
                    Mensal
                  </button>
                  <button className={`toggle-button ${!isMonthly ? "annual selected" : "monthly"}`} onClick={togglePrice}>
                    Anual
                  </button>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="pric-tables">
              <div className="row">
                <div className="col-md-4">
                  <div className="item sm-mb50">
                    <div className={`type text-center mb-15 ${isMonthly ? "selected" : ""}`}>
                      <h5 className="pricing-title">Gratuito</h5>
                    </div>
                    <div className="amount text-center mb-40">
                      <h3>
                         0 {/* Preço ajustado */}
                      </h3>
                    </div>
                    <div className="order mb-40">
                      <a href="https://link.bio.br/register" className="butn-gray rounded buton">
                        <span>Escolher Este</span>
                      </a>
                    </div>
                    <div className="feat">
                    <ul>
                        <li>
                          <span className="pricing-items">
                            2
                          </span>
                          Linkpages
                        </li>

                        <li>
                          <span className="pricing-items">
                            3
                          </span>
                          blocos por Linkpage
                        </li>

                        <li>
                          <span className="pricing-items-2">
                            36
                          </span>
                          blocos Linkpages habilitados
                        </li>

                        <li>
                          <span className="pricing-items">
                            4
                          </span>
                          URLs encurtadas
                        </li>

                        <li>
                          <span className="pricing-items">
                            1
                          </span>
                          links de arquivos no lbDrive
                        </li>

                        <li>
                          <span className="pricing-items">
                            1
                          </span>
                          links Vcard
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="item active">
                    <div className={`type text-center mb-15 ${!isMonthly ? "selected" : ""}`}>
                      <h5 className="pricing-title">Promissor (Preço simbólico)</h5>
                    </div>
                    <div className="amount text-center mb-40">
                      <h3>
                        <span>BRL</span> {isMonthly ? "4.97" : "49"} {/* Preço ajustado */}
                      </h3>
                    </div>
                    <div className="order mb-40">
                      <a href="https://link.bio.br/register?redirect=pay/4" className="butn-gr rounded buton">
                        <span>Escolher Este</span>
                      </a>
                    </div>
                    <div className="feat">
                    <ul>
                    <li>
                          <span className="pricing-items">
                            5
                          </span>
                          Linkpages
                        </li>

                        <li>
                          <span className="pricing-items">
                            10
                          </span>
                          blocos por Linkpage
                        </li>

                        <li>
                          <span className="pricing-items-2">
                            37
                          </span>
                          blocos Linkpages habilitados
                        </li>

                        <li>
                          <span className="pricing-items">
                            5
                          </span>
                          URLs encurtadas
                        </li>

                        <li>
                          <span className="pricing-items">
                            2
                          </span>
                          links de arquivos no lbDrive
                        </li>

                        <li>
                          <span className="pricing-items">
                            2
                          </span>
                          links Vcard
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="item active">
                    <div className={`type text-center mb-15 ${!isMonthly ? "selected" : ""}`}>
                      <h5 className="pricing-title">Rumo ao topo</h5>
                    </div>
                    <div className="amount text-center mb-40">
                      <h3>
                        <span>BRL</span> {isMonthly ? "9" : "99"} {/* Preço ajustado */}
                      </h3>
                    </div>
                    <div className="order mb-40">
                      <a href="https://link.bio.br/register?redirect=pay/1" className="butn-gr rounded buton">
                        <span>Escolher Este</span>
                      </a>
                    </div>
                    <div className="feat">
                    <ul>
                    <li>
                          <span className="pricing-items">
                            6
                          </span>
                          Linkpages
                        </li>

                        <li>
                          <span className="pricing-items">
                            10
                          </span>
                          blocos por Linkpage
                        </li>

                        <li>
                          <span className="pricing-items-2">
                            35
                          </span>
                          blocos Linkpages habilitados
                        </li>

                        <li>
                          <span className="pricing-items">
                            12
                          </span>
                          URLs encurtadas
                        </li>

                        <li>
                          <span className="pricing-items">
                            5
                          </span>
                          links de arquivos no lbDrive
                        </li>

                        <li>
                          <span className="pricing-items">
                            5
                          </span>
                          links Vcard
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricePackages;
