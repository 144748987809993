import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight,faUsers, faLink, faQrcode, faChartBar} from "@fortawesome/free-solid-svg-icons";

const Services7 = ({ lightTheme }) => {
  return (
    <section className="serv-block section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="serv-img md-mb50">
              <img src={lightTheme ? "/img/mobile-app/app-img/light/s3.png" : "/img/mobile-app/app-img/s3.png"} alt="" />
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 valign">
            <div className="content">
              <h6 className="stit mb-30">
              <FontAwesomeIcon icon={faUsers} className="mr-2" style={{ color: "#38b2ac" }} />Linkpages LINKS
              </h6>
              <h2 className="mb-30">Crie páginas de link na bio altamente personalizáveis com muita facilidade.</h2>

              

              <div className="list-feat mt-40">
                <ul>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Cores personalizadas as da sua marca
                  </li>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Vários componentes prontos, é só usar
                  </li>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Configurações de SEO (Motor de busca Google)
                  </li>
                  <li>
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Função de proteção por senha e aviso de conteúdo sensível
                  </li>

                  <div className="butons mt-40">
                    <a href="#0" className="butn-gr rounded buton">
                      <span>Começar Agora</span>
                      <FontAwesomeIcon icon={faArrowRight} className="ml-2 mr-2"/>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div className="row mt-80">
          <div className="col-lg-6 valign">
            <div className="content md-mb50">
              <h6 className="stit mb-30">
                 <FontAwesomeIcon icon={faLink} className="mr-2" style={{ color: "#38b2ac" }} /> Encurtador de links
              </h6>
              <h2 className="mb-30">
              Sim! Você também pode usar nosso serviço como um encurtador.
              </h2>
              

              <div className="list-feat mt-40">
                <ul>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Agendamento e limites de expiração
                  </li>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Segmentação por país, dispositivo e idioma
                  </li>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Rotação A/B
                  </li>
                  <li>
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Proteção por senha, aviso de conteúdo sensível
                  </li>

                  <div className="butons mt-40">
              <a href="#0" className="butn-gr rounded buton">
                  <span>Começar Agora</span>
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2 mr-2"/>
                </a>
              </div>
                </ul>
              </div>

            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="serv-img">
              <img src={lightTheme ? "/img/mobile-app/app-img/light/short-link.png" : "/img/mobile-app/app-img/short-link.png"} alt="" />
            </div>
          </div>
        </div>


        <div className="row mt-80">
          <div className="col-lg-5">
            <div className="serv-img md-mb50">
              <img src={lightTheme ? "/img/mobile-app/app-img/light/qr-code.png" : "/img/mobile-app/app-img/qr-code.png"} alt="" />
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 valign">
            <div className="content">
              <h6 className="stit mb-30">
              <FontAwesomeIcon icon={faQrcode} className="mr-2" style={{ color: "#38b2ac" }} />Gerador de QR Code
              </h6>
              <h2 className="mb-30">Sistema gerador de QR Code completo com modelos fáceis de usar.</h2>

              

              <div className="list-feat mt-40">
                <ul>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Cores personalizadas com gradientes
                  </li>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Logotipo personalizado
                  </li>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    QR Code de WiFi, Vcard, calendário, localização, etc modelos
                  </li>
                  <li>
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Modelo de Vcard, WiFi, Calendário, Localização, etc.
                  </li>

                  <div className="butons mt-40">
                    <a href="#0" className="butn-gr rounded buton">
                      <span>Começar Agora</span>
                      <FontAwesomeIcon icon={faArrowRight} className="ml-2 mr-2"/>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-80">
          <div className="col-lg-6 valign">
            <div className="content md-mb50">
              <h6 className="stit mb-30">
                 <FontAwesomeIcon icon={faChartBar} className="mr-2" style={{ color: "#38b2ac" }} /> Analytics dashboard
              </h6>
              <h2 className="mb-30">
              Fácil de entender, com análise detalhada para todos os seus links.
              </h2>
              

              <div className="list-feat mt-40">
                <ul>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Países e cidades
                  </li>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Referenciadores e UTMs
                  </li>
                  <li className="mb-20">
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Dispositivos e sistemas operacionais
                  </li>
                  <li>
                    <i className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                      </svg>
                    </i>
                    Navegadores, idiomas
                  </li>

                  <div className="butons mt-40">
              <a href="#0" className="butn-gr rounded buton">
                  <span>Começar Agora</span>
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2 mr-2"/>
                </a>
              </div>
                </ul>
              </div>

            </div>
          </div>
          <div className="col-lg-5 offset-lg-1">
            <div className="serv-img">
              <img src={lightTheme ? "/img/mobile-app/app-img/light/analytics.jpg" : "/img/mobile-app/app-img/analytics.jpg"} alt="" />
            </div>
          </div>
        </div>


      </div>
      <div className="circle-blur"></div>
      <div className="circle-blur two"></div>
    </section>
  );
};

export default Services7;
