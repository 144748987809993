import React, { useState, useEffect, useRef } from "react";
import LightTheme from "layouts/Light";
import DarkTheme from "layouts/Dark";
import NavbarMobileApp from "components/Navbar-mobile-app/navbar-linkbio";
import Intro6 from "components/Intro6/intro6";
import Clients3 from "components/Clients3/numbers-Section";
import Services7 from "components/Services7/servicos1";
import Services8 from "components/Services8/servicos8";
import Screenshots from "components/Screenshots/screenshotsLinkBio";
import Progress from "components/Progress/tags";
import VideoWithTeam from "components/Video-with-team/GalleryIA";
import PricePackages from "components/Price-packages/planos";
import Testimonials from "components/Testimonials/Comentarios";
import Blogs2 from "components/Blogs2/acordeao";
import Footer2 from "components/Footer2/footer2";
import Progresis from "components/Progress/progressLinkBio";

const MobileAppLight = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const navbarRef = useRef(null);

  const toggleTheme = () => {
    setIsDarkTheme(prevTheme => !prevTheme);
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = navbarRef.current;
      if (navbar) {
        if (window.pageYOffset > 300) {
          navbar.classList.add("nav-scroll");
        } else {
          navbar.classList.remove("nav-scroll");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navbarRef]);

  return (
    <>
      <NavbarMobileApp ref={navbarRef} />
      {isDarkTheme ? (
        <DarkTheme mobileappstyle>
          <Intro6 />
          <Clients3 />
          <Services7 lightTheme />
          <Services8 />
          <Screenshots />
          <Progress />
          <Progresis />
          <VideoWithTeam />
          <PricePackages />
          <Testimonials />
          <Blogs2 />
          <Footer2 toggleTheme={toggleTheme} isDarkTheme={isDarkTheme} />
        </DarkTheme>
      ) : (
        <LightTheme mobileappstyle>
          <Intro6 />
          <Clients3 />
          <Services7 lightTheme />
          <Services8 />
          <Screenshots />
          <Progress />
          <Progresis />
          <VideoWithTeam />
          <PricePackages />
          <Testimonials />
          <Blogs2 />
          <Footer2 toggleTheme={toggleTheme} isDarkTheme={isDarkTheme} />
        </LightTheme>
      )}
      
    </>
  );
};

export const Head = () => {
  return (
    <>
      <title>Vie - Mobile App Light</title>
      <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap" rel="stylesheet" />
    </>
  )
}

export default MobileAppLight;
