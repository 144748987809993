import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";

const Intro6 = () => {
  return (
    <header className="mobile-app valign">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 valign">
            <div className="caption">
              <h1 className="mb-20">
              O Link na Bio dos Brasileiros
              </h1>
              <p className="p-banner">
              Com linkbioBR você cria Linkpages incríveis, encurta links, cria bio para Instagram, links de eventos, QR Code, VCards, compartilha arquivos e muito mais.
              </p>
              <div className="butons mt-40">
                <a href="#0" className="butn-gr rounded buton">
                  <span>Começar Agora</span>
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2 mr-2"/>
                  <i className="icon">
                  </i>
                </a>

                <a href="#0" className="butn-bord-dark rounded buton ml-2">
                  <span>Veja o Exemplo</span>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2 mr-2"   />
                  <i className="icon">
                  </i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-5 offset-lg-1">
            <div className="img">
              <img src="/img/mobile-app/img-banner-principal.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Intro6;
